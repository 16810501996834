.footer {
    padding-top: 10em;
    margin-top: 5em;
}


.start {
    .logo {
        width: 20%;
        
    }
}